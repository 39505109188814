import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Spinner } from '@chakra-ui/react';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const PrivacyTermsPage = () => {
  const [terms, setTerms] = useState({ title: '', content: '' });
  const [loading, setLoading] = useState(true);

  // Fetch the privacy terms from the API
  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/privacy`);
        const data = await response.json();

        if (data) {
          setTerms(data);
        }
      } catch (error) {
        console.error('Failed to fetch privacy terms:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  if (loading) {
    // Show loading spinner while terms are being fetched
    return (
      <Box p={4} textAlign="center">
        <Spinner size="xl" />
        <Text>Carregando...</Text>
      </Box>
    );
  }

  return (
    <Box p={4}>
      {/* Display the title */}
      <Heading size="xl" mb={4}>
        {terms.title}
      </Heading>

      {/* Display the privacy terms content */}
      <Box
        dangerouslySetInnerHTML={{ __html: terms.content }}
        style={{
          padding: '10px', // Optional: Add padding for better readability
          border: '1px solid #ccc', // Optional: Add a border to define the content area
          borderRadius: '4px', // Optional: Slight border radius for styling
        }}
      />
    </Box>
  );
};

export default PrivacyTermsPage;
