export async function fetchSiteSettings() {
    try {
  
      // Use the API_BASE_URL from environment variables depending on the environment
      const API_BASE_URL = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_VERCEL_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;
  
      // Construct the full API URL
      const apiUrl = `${API_BASE_URL}api/settings/site`;

      // Fetch the site settings from the backend API
      const response = await fetch(apiUrl);
  

  
      // Check if the response is a success (status code 200-299)
      if (!response.ok) {
        console.error('Error: Received non-OK response from the API:', response.status);
        const errorText = await response.text();  // Capture the error response (likely HTML)
        return;  // Stop execution if the API call fails
      }
  
      // Check if the response contains JSON data
      if (response.headers.get('Content-Type')?.includes('application/json')) {
        const data = await response.json();  // Parse the JSON response
  
        // Update the document title
        if (data.title) {
          document.title = data.title;
        } else {
          console.warn('No title found in the fetched data');
        }
  
        // Update the favicon if it's available as a base64 string
        if (data.faviconBase64) {
          const faviconLink = document.getElementById('dynamic-favicon');
          faviconLink.href = `data:image/x-icon;base64,${data.faviconBase64}`;
        } else {
          console.warn('No faviconBase64 found in the fetched data');
        }
      } else {
        console.error('The response is not JSON. It might be HTML or something else.');
        const text = await response.text();  // Get the HTML or error message
      }
    } catch (error) {
      console.error('Error fetching site settings:', error);
    }
  }
  
  // Call the function to fetch and update the site title and favicon
  fetchSiteSettings();
  