import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Heading,
  Flex,
  Text,
  useToast,
  Spinner,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const ManageHelp = () => {
  const [help, setHelp] = useState({ title: '', content: '' });
  const [link, setLink] = useState(''); // Link state for link mode
  const [isLinkMode, setIsLinkMode] = useState(false); // Track link mode switch
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Fetch the current Help content on component mount or reload
  useEffect(() => {
    fetchHelp();
  }, [reload]);

  // Fetch Help data from the API
  const fetchHelp = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/help`);
      const data = await response.json();
      if (data) {
        setHelp(data);
        setLink(data.link || ''); // Set initial link if available
        setIsLinkMode(data.isLinkMode || false); // Set mode based on fetched data
      }
      console.log('Fetched help data:', data);
    } catch (error) {
      console.error('Failed to fetch help:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle form input change for the title
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHelp({ ...help, [name]: value });
  };

  // Handle content change in the ReactQuill editor
  const handleContentChange = (content) => {
    setHelp({ ...help, content });
  };

  // Update Help content or link with a PUT request
  const handleUpdateHelp = async () => {
    const token = localStorage.getItem('token');

    const updateData = isLinkMode 
      ? { link, isLinkMode } 
      : { ...help, isLinkMode }; 

    console.log("Updating Help with the following data:", updateData); // Debug log

    try {
      const response = await fetch(`${API_BASE_URL}api/help`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      });

      console.log("Response status:", response.status); // Debug response status
      if (!response.ok) {
        throw new Error('Failed to update help');
      }

      setReload(!reload);
      toast({
        title: "Sucesso!",
        description: isLinkMode ? "Link atualizado com sucesso." : "Ajuda atualizada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating help:', error);
    }
  };

  // Define toolbar options for ReactQuill editor
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, false] }],
      [{ 'font': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['blockquote', 'code-block'],
      ['link', 'image', 'video'],
      ['clean'],
      [{ 'direction': 'rtl' }],
    ],
  };

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'link', 'image',
  ];

  if (loading) {
    return (
      <Box p={4} textAlign="center">
        <Spinner size="xl" />
        <Text>Carregando...</Text>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Gestão da página Ajuda</Heading>
      <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel htmlFor="link-switch" mb="0">
          Modo de Link
        </FormLabel>
        <Switch
          id="link-switch"
          isChecked={isLinkMode}
          onChange={() => setIsLinkMode(!isLinkMode)}
        />
      </FormControl>
      
      {isLinkMode ? (
        <Flex direction="column" gap={4}>
          <Input
            placeholder="Digite o link aqui"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <Button onClick={handleUpdateHelp} colorScheme="blue">
            Salvar Link
          </Button>
        </Flex>
      ) : (
        <Flex
          direction="column"
          gap={4}
          style={{
            height: '800px',
            overflowY: 'scroll',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <Input
            placeholder="Título"
            name="title"
            value={help.title}
            onChange={handleInputChange}
          />
          <ReactQuill
            theme="snow"
            value={help.content}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
          />
        </Flex>
      )}
      
      {!isLinkMode && (
        <Flex direction="column" gap={4}>
          <Button onClick={handleUpdateHelp} colorScheme="blue">
            Atualizar Ajuda
          </Button>
        </Flex>
      )}

      {/* Preview */}
      <Box mt={8}>
        <Heading size="lg" p={6}>Como aparece o Documento</Heading>
        <Text fontWeight="bold">{help.title}</Text>
        {isLinkMode ? (
          <Text color="blue.500" mt={2}>Link: <a href={link} target="_blank" rel="noopener noreferrer">{link}</a></Text>
        ) : (
          <Box
            dangerouslySetInnerHTML={{ __html: help.content }}
            style={{
              height: '800px',
              width: '760px',
              overflowY: 'scroll',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ManageHelp;
