import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Image,
  HStack,
  Circle,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const sizeOptions = {
  small: { base: '80px', md: '250px' },
  medium: { base: '100px', md: '350px' },
  large: { base: '200px', md: '550px' },
};

const UpperCarousel = () => {
  const { carouselItemId } = useParams();
  const [carouselItems, setCarouselItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const autoChangeInterval = 7000; // Change page every 7 seconds
  const history = useNavigate();

  useEffect(() => {
    const fetchCarouselItems = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/uppercarousel`);
        const data = await response.json();
        setCarouselItems(data.sort((a, b) => a.order - b.order)); // Sort items by order
      } catch (error) {
        console.error('Error fetching carousel items:', error);
      }
    };

    fetchCarouselItems();
  }, []);

  useEffect(() => {
    if (carouselItems.length > 1) {
      const intervalId = setInterval(() => {
        nextSlide();
      }, autoChangeInterval);

      return () => clearInterval(intervalId);
    }
  }, [currentIndex, carouselItems]);

  const prevSlide = () => {
    if (carouselItems.length > 1) {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? carouselItems.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    }
  };

  const nextSlide = () => {
    if (carouselItems.length > 1) {
      const isLastSlide = currentIndex === carouselItems.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handleImageClick = (carouselItemId) => {
    history(`/related-products/${carouselItemId}`);
  };

  if (carouselItems.length === 0) {
    return null;
  }

  const currentItemSize = carouselItems[currentIndex]?.size || 'medium';

  return (
    <Box position="relative" width="100%" height={{ base: `calc(${sizeOptions[currentItemSize].base} + 20px)`, md: `calc(${sizeOptions[currentItemSize].md} + 70px)` }} overflow="hidden">
      {carouselItems.map((item, index) => (
        <Box
          key={item._id}
          display={index === currentIndex ? 'block' : 'none'}
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height={sizeOptions[currentItemSize].md}
          onClick={() => handleImageClick(item._id)}
          cursor="pointer"
        >
          <Image 
            src={`data:image/png;base64,${item.imageUrl}`} 
            alt={`Carousel image ${index}`} 
            width="100%" 
            height={sizeOptions[currentItemSize]}
            objectFit="fill" 
            objectPosition="center" 
          />
        </Box>
      ))}
      {carouselItems.length > 1 && (
        <>
          <IconButton
            icon={<IoIosArrowBack />}
            position="absolute"
            left="1px"
            top="42%"
            height={`calc(${sizeOptions[currentItemSize].md} + 0px)`}
            transform="translateY(-50%)"
            zIndex="10"
            onClick={prevSlide}
            aria-label="Previous Slide"
            opacity="0"
            _hover={{ opacity: 0.4 }}
          />
          <IconButton
            icon={<IoIosArrowForward />}
            position="absolute"
            right="1px"
            top="42%"
            height={`calc(${sizeOptions[currentItemSize].md} + 0px)`}
            transform="translateY(-50%)"
            zIndex="10"
            onClick={nextSlide}
            aria-label="Next Slide"
            opacity="0"
            _hover={{ opacity: 0.4 }}
          />
        </>
      )}
      <Box position="relative" width="100%" display="flex" justifyContent="center" mt={{ base: `calc(${sizeOptions[currentItemSize].base} + 10px)`, md: `calc(${sizeOptions[currentItemSize].md} + 10px)` }}> {/* Container to center circles */}
        <HStack
          spacing={2}
          zIndex="10"
        >
          {carouselItems.length > 1 && carouselItems.map((_, index) => (
            <Circle
              key={index}
              size={index === currentIndex ? '10px' : '8px'}
              bg={index === currentIndex ? 'gray.800' : 'gray.400'}
              cursor="pointer"
              onClick={() => goToSlide(index)}
            />
          ))}
        </HStack>
      </Box>
    </Box>
  );
};

export default UpperCarousel;