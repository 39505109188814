import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, HeatmapLayer } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "400px",
  width: "100%"
};

const center = {
  lat: -23.550520,
  lng: -46.633308
};

const HeatMap = ({ data }) => {
  const [heatMapData, setHeatMapData] = useState([]);
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setIsMapsLoaded(true);
  }, []);

  useEffect(() => {
    if (isMapsLoaded && window.google && window.google.maps) {
      const processedData = data.map(location => ({
        location: new window.google.maps.LatLng(location.lat, location.lng),
        weight: location.weight,
      }));
      setHeatMapData(processedData);
    }
  }, [isMapsLoaded, data]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={["visualization"]}
      onLoad={onLoad}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={12}
      >
        {heatMapData.length > 0 && (
          <HeatmapLayer
            data={heatMapData}
            options={{ radius: 20 }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default HeatMap;
