import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Image, Stack, Text, Select, IconButton } from '@chakra-ui/react';
import { FaTrash, FaEdit } from 'react-icons/fa';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const LogoUpload = ({ onUpdateLogo }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [logoType, setLogoType] = useState('superior');
  const [logos, setLogos] = useState({ /*principal: null,*/ superior: null, rodape: null });
  const [title, setTitle] = useState(''); // State for title
  const [favicon, setFavicon] = useState(null); // State for favicon
  const [faviconPreview, setFaviconPreview] = useState(null); // Preview for favicon
  
  useEffect(() => {
    fetchLogos();
    fetchSiteSettings(); // Fetch title and favicon on component mount
  }, []);

  const fetchLogos = async () => {
    try {
      const types = [/*'principal', */'superior', 'rodape'];
      const promises = types.map(type => fetch(`${API_BASE_URL}api/settings/logo?type=${type}`).then(res => res.json()));
      const logosData = await Promise.all(promises);
      const logos = logosData.reduce((acc, logo, index) => {
        acc[types[index]] = logo;
        return acc;
      }, {});
      setLogos(logos);
    } catch (error) {
      console.error('Error fetching logos:', error);
    }
  };

  const fetchSiteSettings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/site`);
      const data = await response.json();
      setTitle(data.title); // Load title
      if (data.faviconBase64) {
        setFaviconPreview(`data:image/x-icon;base64,${data.faviconBase64}`); // Set favicon preview
      }
    } catch (error) {
      console.error('Error fetching site settings:', error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleFaviconChange = (event) => {
    const file = event.target.files[0];
    setFavicon(file);
    setFaviconPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append('logo', file);
    formData.append('type', logoType);

    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/logo/upload`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        onUpdateLogo(data.logo.base64Data);
        setPreview(`data:image/png;base64,${data.logo.base64Data}`);
        fetchLogos(); // Refresh the logos list
      } else {
        console.error('Failed to upload logo');
      }
    } catch (error) {
      console.error('Error uploading logo:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('logo', file);
    formData.append('type', logoType);

    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/logo/update`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        onUpdateLogo(data.logo.base64Data);
        setPreview(`data:image/png;base64,${data.logo.base64Data}`);
        fetchLogos(); // Refresh the logos list
      } else {
        console.error('Failed to update logo');
      }
    } catch (error) {
      console.error('Error updating logo:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (type) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/logo/delete?type=${type}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        fetchLogos(); // Refresh the logos list
      } else {
        console.error('Failed to delete logo');
      }
    } catch (error) {
      console.error('Error deleting logo:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTitleUpdate = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/site/title`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title }),
      });

      if (!response.ok) {
        console.error('Failed to update title');
      }
    } catch (error) {
      console.error('Error updating title:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFaviconUpload = async () => {
    if (!favicon) return;

    const formData = new FormData();
    formData.append('favicon', favicon);

    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/site/favicon`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setFaviconPreview(`data:image/x-icon;base64,${data.faviconBase64}`);
      } else {
        console.error('Failed to upload favicon');
      }
    } catch (error) {
      console.error('Error uploading favicon:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFaviconDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/settings/site/favicon`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setFaviconPreview(null); // Remove favicon preview on successful deletion
      } else {
        console.error('Failed to delete favicon');
      }
    } catch (error) {
      console.error('Error deleting favicon:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
              {/* Title update section */}
      <Box mt={4}>
        <FormControl>
          <FormLabel>Atualizar Titulo do Site</FormLabel>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter site title" />
          <Button mt={2} onClick={handleTitleUpdate} isLoading={loading} isDisabled={loading}>
            Atualizar Title
          </Button>
        </FormControl>
      </Box>

      {/* Favicon update section */}
      <Box mt={4}>
        <FormControl>
          <FormLabel>Atualizar Favicon</FormLabel>
          <Input type="file" accept="image/x-icon" onChange={handleFaviconChange} />
          {faviconPreview && <Image src={faviconPreview} alt="Favicon Preview" boxSize="50px" objectFit="cover" mt={4} />}
          <Stack direction="row" mt={2}>
            <Button onClick={handleFaviconUpload} isLoading={loading} isDisabled={loading}>
              Upload Favicon
            </Button>
            <Button onClick={handleFaviconDelete} isLoading={loading} isDisabled={loading}>
              Deletar Favicon
            </Button>
          </Stack>
        </FormControl>
      </Box>



      <FormControl as="form" onSubmit={handleSubmit}>
        <FormLabel>Upload Novo Logo</FormLabel>
        <Select value={logoType} onChange={(e) => setLogoType(e.target.value)} mb={4}>
          {/*<option value="principal">Logo Principal</option>*/}
          <option value="superior">Logo Superior</option>
          <option value="rodape">Logo Rodapé</option>
        </Select>
        <Input type="file" accept="image/*" onChange={handleFileChange} />
        {preview && <Image src={preview} alt="Logo Preview" boxSize="100px" objectFit="cover" mt={4} />}
        <Button type="submit" mt={4} isLoading={loading} isDisabled={loading}>
          Upload
        </Button>
      </FormControl>



      {/* Logos display */}
      <Box mt={4}>
        <Text>Logos Atuais:</Text>
        {Object.keys(logos).map((type) => (
          logos[type] && (
            <Stack key={type} direction="row" alignItems="center" mt={4} spacing={4}>
              <Image src={`data:image/png;base64,${logos[type].base64Data}`} alt={`${type} Logo`} boxSize="100px" objectFit="cover" />
              <Text>{type.charAt(0).toUpperCase() + type.slice(1)} Logo</Text>
              <IconButton icon={<FaEdit />} onClick={() => setLogoType(type)} aria-label="Edit logo" />
              <IconButton icon={<FaTrash />} onClick={() => handleDelete(type)} aria-label="Delete logo" />
            </Stack>
          )
        ))}
      </Box>
    </Box>
  );
};

export default LogoUpload;
