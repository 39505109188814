const mockGeographyData = [
  { id: 'AC', value: 'R$500.000,00' }, // Acre
  { id: 'AL', value: 'R$100.000,00' }, // Alagoas
  { id: 'AM', value: 'R$700.000,00' }, // Amazonas
  { id: 'AP', value: 'R$400.000,00' }, // Amapá
  { id: 'BA', value: 'R$500.000,00' }, // Bahia
  { id: 'CE', value: 'R$120.000,00' }, // Ceará
  { id: 'DF', value: 'R$180.000,00' }, // Distrito Federal
  { id: 'ES', value: 'R$850.000,00' }, // Espírito Santo
  { id: 'GO', value: 'R$900.000,00' }, // Goiás
  { id: 'MA', value: 'R$50.000,00' }, // Maranhão
  { id: 'MG', value: 'R$40.000,00' }, // Minas Gerais
  { id: 'MS', value: 'R$456.000,00' }, // Mato Grosso do Sul
  { id: 'MT', value: 'R$258.000,00' }, // Mato Grosso
  { id: 'PA', value: 'R$789.000,00' }, // Pará
  { id: 'PB', value: 'R$321.000,00' }, // Paraíba
  { id: 'PE', value: 'R$429.000,00' }, // Pernambuco
  { id: 'PI', value: 'R$741.000,00' }, // Piauí
  { id: 'PR', value: 'R$431.000,00' }, // Paraná
  { id: 'RJ', value: 'R$519.000,00' }, // Rio de Janeiro
  { id: 'RN', value: 'R$33.000,00' }, // Rio Grande do Norte
  { id: 'RO', value: 'R$78.000,00' }, // Rondônia
  { id: 'RR', value: 'R$45.000,00' }, // Roraima
  { id: 'RS', value: 'R$96.000,00' }, // Rio Grande do Sul
  { id: 'SC', value: 'R$123.000,00' }, // Santa Catarina
  { id: 'SE', value: 'R$248.000,00' }, // Sergipe
  { id: 'SP', value: 'R$1.500.000,00' }, // São Paulo
  { id: 'TO', value: 'R$15.000,00' }, // Tocantins
];

export default mockGeographyData;
