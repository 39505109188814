import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Image, Text, HStack, VStack } from '@chakra-ui/react';

// Define size variables
const MOBILE_WIDTH = '100%';
const MOBILE_HEIGHT = 'auto';
const DESKTOP_WIDTH = '200px';
const DESKTOP_HEIGHT = '450px';
const IMAGE_MOBILE_HEIGHT = '150px';
const IMAGE_DESKTOP_HEIGHT = '250px';

const ProductCardRelated = ({ product }) => {
  if (!product) {
    return null; // Don't render the component if the product is undefined
  }

  const base64ImageSrc = product.images && product.images.length > 0
    ? `data:image/png;base64,${product.images[0]}`
    : ''; // Use a placeholder or empty string if no image is available

  // Calculate the discount percentage
  const discountPercentage = product.originalPrice
    ? Math.round(((product.originalPrice - product.price) / product.originalPrice) * 100)
    : null;

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Helper function to filter unique colors
  const getUniqueColors = (stock) => {
    const colorMap = {};
    return stock.filter(stockItem => {
      if (!colorMap[stockItem.colorId.hex]) {
        colorMap[stockItem.colorId.hex] = true;
        return true;
      }
      return false;
    });
  };

  const uniqueColors = product.stock ? getUniqueColors(product.stock) : [];

  return (
    <Link to={`/products/${product._id}`} onClick={handleClick}>
      <Box 
        borderWidth="0px" 
        borderRadius="lg" 
        overflow="hidden" 
        p={1} 
        bg="white" 
        width={{ base: MOBILE_WIDTH, md: DESKTOP_WIDTH }} 
        height={{ base: MOBILE_HEIGHT, md: DESKTOP_HEIGHT }} 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center"
      >
        {discountPercentage && (
          <Box position="absolute" top="8px" left="8px" bg="red.500" color="white" px={2} borderRadius="md">
            -{discountPercentage}%
          </Box>
        )}
        <Image 
          src={base64ImageSrc} 
          alt={product.name || 'Product image'} 
          borderRadius="md" 
          height={{ base: IMAGE_MOBILE_HEIGHT, md: IMAGE_DESKTOP_HEIGHT }} 
          objectFit="cover"
          alignSelf="center" 
        />
        <Box p="4" textAlign="center">
          <VStack spacing={2}>
            <Text
              mt="0"
              fontWeight="bold"
              fontSize={{ base: "md", md: "md" }}
              as="h4"
              lineHeight="tight"
              color="black"
              wordBreak="break-word"
            >
              {product.name}
            </Text>
            <Box color="black" mt="0">
              {product.budgetOn ? (
                <Text as="span" fontWeight="bold" color="red.500">
                  Sob Consulta
                </Text>
              ) : (
                <>
                  {product.originalPrice && (
                    <Text as="span" textDecoration="line-through" color="gray.500" mr={1}>
                      R${product.originalPrice.toFixed(2)}
                    </Text>
                  )}
                  <Text as="span" fontWeight="bold">
                    R${product.price.toFixed(2)}
                  </Text>
                </>
              )}
            </Box>
            <HStack spacing={1} mt="0">
              {uniqueColors.slice(0, 3).map((stockItem, index) => (
                <Box
                  key={index}
                  w="15px"
                  h="15px"
                  bg={stockItem.colorId.hex}
                  borderRadius="full"
                  border="1px solid gray"
                />
              ))}
              {uniqueColors.length > 3 && (
                <Text fontSize="sm" color="gray.500">
                  +{uniqueColors.length - 3}
                </Text>
              )}
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Link>
  );
};

export default ProductCardRelated;
