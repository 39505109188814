import React, { useEffect, useState, useCallback } from 'react';
import { Box, Flex, Button, Spinner, Text } from '@chakra-ui/react';
import ProductCard from './ProductCard';
import UpperCarousel from '../Carousel/UpperCarousel';

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const ITEMS_PER_PAGE = 20; // Adjust the number of items per batch as needed

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}api/productsList/list?page=${page}&limit=${ITEMS_PER_PAGE}`);
      const data = await response.json();

      if (!data || !data.products) {
        throw new Error('Fetched data is not valid');
      }

      setProducts(data.products);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 2);
    let endPage = Math.min(totalPages, page + 2);

    if (page <= 3) {
      startPage = 1;
      endPage = Math.min(totalPages, 5);
    } else if (page >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4);
      endPage = totalPages;
    }

    if (startPage > 1) {
      pageNumbers.push(
        <Button
          key="first"
          onClick={handleFirstPage}
          mx={1}
        >
          1
        </Button>
      );
      pageNumbers.push(<Box key="first-ellipsis" mx={1}>...</Box>);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          disabled={page === i}
          mx={1}
          bg={page === i ? 'blue.500' : 'transparent'}
          color={page === i ? 'white' : 'black'}
          _hover={{ bg: page === i ? 'blue.600' : 'gray.200' }}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(<Box key="last-ellipsis" mx={1}>...</Box>);
      pageNumbers.push(
        <Button
          key="last"
          onClick={handleLastPage}
          mx={1}
        >
          {totalPages}
        </Button>
      );
    }

    return pageNumbers;
  };

  return (
    <Box>
      <UpperCarousel />
      <Flex wrap="wrap" justify="center" spacing={8} mt={-12}>
        {isLoading ? (
          <Flex justify="center" align="center" height="100vh">
            <Spinner size="xl" />
            <Text ml={4} fontSize="xl">Carregando...</Text>
          </Flex>          
        ) : (
          products.map((product) => (
            <Box key={product._id} p={2}>
              <ProductCard product={product} />
            </Box>
          ))
        )}
      </Flex>
      {!isLoading && (
        <Flex justify="center" mt={4} align="center" mb={4}>
          {renderPageNumbers()}
        </Flex>
      )}
    </Box>
  );
};

export default ProductList;
