import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Heading,
  Select,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image,
  HStack,
  SimpleGrid,
  Textarea,
  Checkbox,
  FormLabel,
} from '@chakra-ui/react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [confirmName, setConfirmName] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [budgetOn, setBudgetOn] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState('');
  const [features, setFeatures] = useState([['']]);
  const [productDetails, setProductDetails] = useState([{ label: '', value: '' }]);
  const [genders, setGenders] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedItemType, setSelectedItemType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredItemTypes, setFilteredItemTypes] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [deleteProduct, setDeleteProduct] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5; // Number of products per page

  const cancelRef = useRef();
  const toast = useToast();

  useEffect(() => {
    fetchProducts(currentPage);
    fetchGenders();
    fetchItemTypes();
    fetchCategories();
    fetchBrands();
  }, [currentPage]);

  const fetchProducts = async (page) => {
    try {
      setIsDataLoading(true);
      const response = await fetch(`${API_BASE_URL}api/productsListManager/list?page=${page}&limit=${itemsPerPage}`);
      const data = await response.json();

      setProducts(data.products);
      setTotalPages(data.totalPages);
      setIsDataLoading(false);
    } catch (error) {
      console.error('Failed to fetch products:', error);
      setIsDataLoading(false);
    }
  };

  const fetchGenders = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/genders`);
      const data = await response.json();
      setGenders(data);
    } catch (error) {
      console.error('Failed to fetch genders:', error);
    }
  };

  const fetchItemTypes = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/itemtypes`);
      const data = await response.json();
      setItemTypes(data);
    } catch (error) {
      console.error('Failed to fetch item types:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/categories`);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/brands`);
      const data = await response.json();
      setBrands(data);
    } catch (error) {
      console.error('Failed to fetch brands:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'price':
        setPrice(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'details':
        setDetails(value);
        break;
      default:
        break;
    }
  };

  const handleImageChange = (e) => {
    setImages([...images, ...Array.from(e.target.files)]);
  };

  const removeImage = (index, type = 'existing') => {
    if (type === 'existing') {
      const newExistingImages = existingImages.filter((_, i) => i !== index);
      setExistingImages(newExistingImages);
    } else {
      const newImages = images.filter((_, i) => i !== index);
      setImages(newImages);
    }
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('price', price);
    formData.append('budgetOn', budgetOn);
    images.forEach(image => formData.append('images', image));
    formData.append('description', description);
    formData.append('details', details);
    formData.append('features', JSON.stringify(features));
    formData.append('productDetails', JSON.stringify(productDetails));
    formData.append('gender', selectedGender);
    formData.append('itemType', selectedItemType);
    formData.append('category', selectedCategory);
    formData.append('brand', selectedBrand);

    try {
      const response = await fetch(`${API_BASE_URL}api/products`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response data:', errorData);
        throw new Error('Failed to add product');
      }

      const data = await response.json();
      setProducts([...products, data]);
      resetForm();
      toast({
        title: 'Produto adicionado.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding product:', error);
      toast({
        title: 'Erro ao adicionar produto.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateProduct = async () => {
    if (!currentProductId) return;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('price', price);
    formData.append('budgetOn', budgetOn);
    formData.append('description', description);
    formData.append('details', details);
    formData.append('features', JSON.stringify(features));
    formData.append('productDetails', JSON.stringify(productDetails));
    formData.append('gender', selectedGender);
    formData.append('itemType', selectedItemType);
    formData.append('category', selectedCategory);
    formData.append('brand', selectedBrand);

    images.forEach(image => formData.append('images', image));
    formData.append('existingImages', JSON.stringify(existingImages));

    try {
      const response = await fetch(`${API_BASE_URL}api/products/${currentProductId}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response data:', errorData);
        throw new Error('Failed to update product');
      }

      const data = await response.json();
      setProducts(products.map((product) => (product._id === currentProductId ? data : product)));
      resetForm();
      toast({
        title: 'Produto atualizado.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating product:', error);
      toast({
        title: 'Erro ao atualizar produto.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteProduct = async () => {
    if (!deleteProduct || deleteProduct.name !== confirmName) {
      toast({
        title: 'Nome incorreto',
        description: 'O nome inserido não corresponde ao nome do produto.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}api/products/${deleteProduct._id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response data:', errorData);
        throw new Error('Failed to delete product');
      }

      setProducts(products.filter((product) => product._id !== deleteProduct._id));
      resetDeleteDialog();
    } catch (error) {
      console.error('Error deleting product:', error);
      toast({
        title: 'Erro ao excluir produto.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const resetForm = () => {
    setCurrentProductId(null);
    setName('');
    setPrice('');
    setBudgetOn(false);
    setExistingImages([]);
    setImages([]);
    setDescription('');
    setDetails('');
    setFeatures([['']]);
    setProductDetails([{ label: '', value: '' }]);
    setSelectedGender('');
    setSelectedItemType('');
    setSelectedCategory('');
    setSelectedBrand('');
  };

  const resetDeleteDialog = () => {
    setConfirmName('');
    setIsDeleteDialogOpen(false);
    setDeleteProduct(null);
  };

  const confirmDelete = (product) => {
    setDeleteProduct(product);
    setIsDeleteDialogOpen(true);
  };

  const handleEditProduct = (product) => {
    setIsLoading(true);
    setCurrentProductId(product._id);
    setName(product.name);
    setPrice(product.price);
    setBudgetOn(product.budgetOn);
    setExistingImages(product.images);
    setDescription(product.description);
    setDetails(product.details);
    setFeatures(product.features);
    setProductDetails(product.productDetails);

    const genderId = product.gender?._id || '';
    const itemTypeId = product.itemType?._id || '';
    const categoryId = product.category?._id || '';

    setSelectedGender(genderId);
    setSelectedItemType('');
    setSelectedCategory('');

    setTimeout(() => {
      const filteredItemTypes = itemTypes.filter(itemType => itemType.gender._id === genderId);
      setFilteredItemTypes(filteredItemTypes);
      setSelectedItemType(itemTypeId);
    }, 0);

    setTimeout(() => {
      const filteredCategories = categories.filter(category => category.itemType._id === itemTypeId);
      setFilteredCategories(filteredCategories);
      setSelectedCategory(categoryId);
      setIsLoading(false);
    }, 0);
    setSelectedBrand(product.brand?._id || '');
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    if (currentProductId) {
      const product = products.find(p => p._id === currentProductId);
      if (product && genders.length && itemTypes.length && categories.length) {
        handleEditProduct(product);
      }
    }
  }, [currentProductId, products, genders, itemTypes, categories]);

  useEffect(() => {
    if (selectedGender) {
      const filtered = itemTypes.filter(itemType => itemType.gender._id === selectedGender);
      setFilteredItemTypes(filtered);
      setSelectedItemType('');
      setSelectedCategory('');
      setFilteredCategories([]);
    }
  }, [selectedGender, itemTypes]);

  useEffect(() => {
    if (selectedItemType) {
      const filtered = categories.filter(category => category.itemType._id === selectedItemType);
      setFilteredCategories(filtered);
      setSelectedCategory('');
    }
  }, [selectedItemType, categories]);

  const handleExportCSV = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/productsListManager/export`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast({
        title: 'Export successful.',
        description: 'Products have been exported to CSV.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to export products:', error);
      toast({
        title: 'Export failed.',
        description: 'An error occurred while exporting products.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };



  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Controle de Produtos</Heading>
      {isLoading && (
        <Box textAlign="center" mb={4}>
          <Spinner size="xl"  mb={4}/>
          <Text>Carregando Produto...</Text>
        </Box>
      )}
      {isDataLoading ? (
        <Box textAlign="center" mb={4}>
          <Spinner size="xl"  mb={4}/>
          <Text>Carregando dados...</Text>
        </Box>
      ) : (
        <Flex mb={4} direction="column" gap={4} opacity={isLoading ? 0.5 : 1} pointerEvents={isLoading ? 'none' : 'all'}>
          <Input
            placeholder="Nome do Produto"
            name="name"
            value={name}
            onChange={handleInputChange}
          />
          <Flex alignItems="center">
            
            <Checkbox
              isChecked={budgetOn}
              onChange={(e) => setBudgetOn(e.target.checked)}
            >
              Preço sob orçamento
            </Checkbox>
            <Input
              isDisabled={budgetOn}
              placeholder="Preço"
              type="number"
              name="price"
              value={price}
              onChange={handleInputChange}
              mr={2}
            />
          </Flex>
          <Box>
            <Text>Imagens Existentes:</Text>
            <Flex>
              {existingImages.map((base64Image, index) => (
                <Box key={index} position="relative" mr={2}>
                  <img
                    src={`data:image/png;base64,${base64Image}`}
                    alt="product"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                  <Button
                    size="xs"
                    colorScheme="red"
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={() => removeImage(index, 'existing')}
                  >
                    X
                  </Button>
                </Box>
              ))}
            </Flex>
          </Box>
          <Box>
            <Text>Novas Imagens:</Text>
            <Flex>
              {images.map((image, index) => (
                <Box key={index} position="relative" mr={2}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt="new-product"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                  <Button
                    size="xs"
                    colorScheme="red"
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={() => removeImage(index, 'new')}
                  >
                    X
                  </Button>
                </Box>
              ))}
            </Flex>
          </Box>
          <Input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
          <Textarea
            isRequired={true}
            placeholder="Descrição é necessária. Escreva aqui adescrição do produto"
            name="description"
            value={description}
            onChange={handleInputChange}
          />
          <Textarea
            placeholder="Detalhes"
            name="details"
            value={details}
            onChange={handleInputChange}
          />
          <Box>
            <Text>Características:</Text>
            {features.map((featureSet, i) => (
              <Box key={i}>
                {featureSet.map((feature, j) => (
                  <Input
                    key={j}
                    placeholder="Características"
                    value={feature}
                    onChange={(e) => {
                      const newFeatures = features.slice();
                      newFeatures[i][j] = e.target.value;
                      setFeatures(newFeatures);
                    }}
                  />
                ))}
                <Button
                  onClick={() => {
                    const newFeatures = features.slice();
                    newFeatures[i].push('');
                    setFeatures(newFeatures);
                  }}
                >
                  Adicionar Características
                </Button>
              </Box>
            ))}
            <Button onClick={() => setFeatures([...features, ['']])}>Adicionar Set de Características</Button>
          </Box>
          <Box>
            <Text>Códigos do Produto:</Text>
            {productDetails.map((detail, i) => (
              <Flex key={i} gap={2}>
                <Input
                  placeholder="Rótulo"
                  value={detail.label}
                  onChange={(e) => {
                    const newDetails = productDetails.slice();
                    newDetails[i].label = e.target.value;
                    setProductDetails(newDetails);
                  }}
                />
                <Input
                  placeholder="Código"
                  value={detail.value}
                  onChange={(e) => {
                    const newDetails = productDetails.slice();
                    newDetails[i].value = e.target.value;
                    setProductDetails(newDetails);
                  }}
                />
                <Button
                  onClick={() => {
                    const newDetails = productDetails.slice();
                    newDetails.splice(i, 1);
                    setProductDetails(newDetails);
                  }}
                >
                  Remover
                </Button>
              </Flex>
            ))}
            <Button onClick={() => setProductDetails([...productDetails, { label: '', value: '' }])}>
              Adicionar Códigos
            </Button>
          </Box>
          <Select
            placeholder="Selecionar Gênero"
            value={selectedGender}
            onChange={(e) => setSelectedGender(e.target.value)}
          >
            {genders.map(gender => (
              <option key={gender._id} value={gender._id}>{gender.name}</option>
            ))}
          </Select>
          <Select
            placeholder="Selecionar Tipo de Item"
            value={selectedItemType}
            onChange={(e) => setSelectedItemType(e.target.value)}
            isDisabled={!selectedGender}
          >
            {filteredItemTypes.map(itemType => (
              <option key={itemType._id} value={itemType._id}>{itemType.name}</option>
            ))}
          </Select>
          <Select
            placeholder="Selecionar Categoria"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            isDisabled={!selectedItemType}
          >
            {filteredCategories.map(category => (
              <option key={category._id} value={category._id}>{category.name}</option>
            ))}
          </Select>
          <Select
            placeholder="Selecionar Marca"
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            {brands.map(brand => (
              <option key={brand._id} value={brand._id}>{brand.name}</option>
            ))}
          </Select>
          {currentProductId ? (
            <Button onClick={handleUpdateProduct} colorScheme="blue">Atualizar Produto</Button>
          ) : (
            <Button onClick={handleAddProduct} colorScheme="blue">Adicionar Produto</Button>
          )}
        </Flex>
      )}
       <Button onClick={handleExportCSV} colorScheme="blue" mb={4}>Exportar Lista de produtos para .CSV</Button>
      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Preço</Th>
            <Th>Gênero</Th>
            <Th>Tipo de Item</Th>
            <Th>Categoria</Th>
            <Th>Marca</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products.map((product) => (
            <Tr key={product._id}>
              <Td>
                <Button variant="link" colorScheme="blue" onClick={() => handleOpenModal(product)}>
                  {product.name}
                </Button>
              </Td>
              <Td>{product.price}</Td>
              <Td>{genders.find(g => g._id === product.gender?._id)?.name || 'N/A'}</Td>
              <Td>{itemTypes.find(it => it._id === product.itemType?._id)?.name || 'N/A'}</Td>
              <Td>{categories.find(c => c._id === product.category?._id)?.name || 'N/A'}</Td>
              <Td>{brands.find(b => b._id === product.brand?._id)?.name || 'N/A'}</Td>
              <Td>
                <Button onClick={() => handleEditProduct(product)} colorScheme="yellow"><FaEdit /></Button>
                <Button onClick={() => confirmDelete(product)} colorScheme="red"><FaTrash /></Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Produto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedProduct && (
              <Box>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
                  <Box>
                    <Text><strong>Nome:</strong> {selectedProduct.name}</Text>
                    <Text><strong>Preço:</strong> {selectedProduct.price}</Text>
                    <Text><strong>Descrição:</strong> {selectedProduct.description}</Text>
                    <Text><strong>Detalhes:</strong> {selectedProduct.details}</Text>
                    <Text><strong>Características:</strong> {selectedProduct.features.join(', ')}</Text>
                    <Text><strong>Códigos do Produto:</strong> {selectedProduct.productDetails.map(detail => `${detail.label}: ${detail.value}`).join(', ')}</Text>
                    <Text><strong>Gênero:</strong> {genders.find(g => g._id === selectedProduct.gender?._id)?.name || 'N/A'}</Text>
                    <Text><strong>Tipo de Item:</strong> {itemTypes.find(it => it._id === selectedProduct.itemType?._id)?.name || 'N/A'}</Text>
                    <Text><strong>Categoria:</strong> {categories.find(c => c._id === selectedProduct.category?._id)?.name || 'N/A'}</Text>
                    <Text><strong>Marca:</strong> {brands.find(b => b._id === selectedProduct.brand?._id)?.name || 'N/A'}</Text>
                  </Box>
                  <Box>
                    <Image
                      src={`data:image/png;base64,${selectedProduct.images[0]}`}
                      alt={selectedProduct.name}
                      fit={'cover'}
                      align={'center'}
                      w={'100%'}
                      h={{ base: '200px', sm: '400px', lg: '500px' }}
                    />
                    <HStack spacing={2} overflowX="auto" py={2}>
                      {selectedProduct.images.map((base64Image, index) => (
                        <Image
                          key={index}
                          src={`data:image/png;base64,${base64Image}`}
                          alt={`Product Image ${index}`}
                          fit={'cover'}
                          align={'center'}
                          w={{ base: '100px', md: '100px' }}
                          h={{ base: '100px', md: '100px' }}
                          cursor={'pointer'}
                          border={'2px solid #000'}
                        />
                      ))}
                    </HStack>
                  </Box>
                </SimpleGrid>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={resetDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Exclusão
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir <strong>{deleteProduct?.name}?</strong> Por favor, insira o nome do item para confirmar.
              <Input
                placeholder="Nome do item"
                value={confirmName}
                onChange={(e) => setConfirmName(e.target.value)}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={resetDeleteDialog}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDeleteProduct} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Flex justifyContent="space-between" mt={4}>
        <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} isDisabled={currentPage === 1}>
          Página Anterior
        </Button>
        <Text>Página {currentPage} de {totalPages}</Text>
        <Button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} isDisabled={currentPage === totalPages}>
          Próxima Página
        </Button>
      </Flex>
    </Box>
  );
};

export default ManageProducts;
