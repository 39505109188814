import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Heading,
  Spinner,
  Image,
} from '@chakra-ui/react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import Select from 'react-select';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageMeasureTables = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [genders, setGenders] = useState([]);  // Genders state
  const [selectedGender, setSelectedGender] = useState(null);  // Selected gender
  const [imageFile, setImageFile] = useState(null);  // For the image file
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);  // For image preview when editing
  const [items, setItems] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [editItemId, setEditItemId] = useState(null);  // If we are editing an existing item

  const fileInputRef = useRef(null);  // Ref for file input field

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsDataLoading(true);
        const categoryData = await fetch(`${API_BASE_URL}api/categories`).then(res => res.json());

        setCategories(Array.isArray(categoryData) ? categoryData : []);
        fetchGenders();  // Fetch genders on component load
        fetchItems();  // Fetch the created items with categories and images
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setIsDataLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchGenders = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/genders`);
      const data = await response.json();
      setGenders(data);  // Set the genders state
    } catch (error) {
      console.error('Failed to fetch genders:', error);
    }
  };

  const fetchItems = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/measuretable`);
      const data = await response.json();
      setItems(Array.isArray(data) ? data : []);
      setIsDataLoading(false);
    } catch (error) {
      console.error('Failed to fetch items:', error);
      setIsDataLoading(false);
    }
  };

  const handleCreateOrUpdateItem = async () => {
    // If you're creating a new item (no editItemId), ensure both category and image are selected
    if (!editItemId && (!selectedCategory || !imageFile)) {
      alert('Por favor, selecione uma categoria e uma imagem.');
      return;
    }
  
    // If you're updating an item and no new image is selected, keep the old image
    const formData = new FormData();
    formData.append('categorieId', selectedCategory?.value || '');  // Optional category
    formData.append('categorieName', selectedCategory?.label || '');  // Optional category name
    if (imageFile) {
      formData.append('image', imageFile);  // Only append image if a new one is selected
    }
  
    try {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: editItemId ? 'PUT' : 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };
  
      const response = await fetch(`${API_BASE_URL}api/measuretable${editItemId ? `/${editItemId}` : ''}`, requestOptions);
  
      if (!response.ok) {
        throw new Error(editItemId ? 'Failed to update item.' : 'Failed to create item.');
      }
  
      const data = await response.json();
  
      if (editItemId) {
        setItems(items.map((item) => (item._id === editItemId ? data : item)));
        alert('Item atualizado com sucesso!');
      } else {
        setItems([...items, data]);
        alert('Item criado com sucesso!');
      }
  
      resetForm();
    } catch (error) {
      console.error(error);
      alert(editItemId ? 'Erro ao atualizar o item.' : 'Erro ao criar o item.');
    }
  };
  

  const handleDeleteItem = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}api/measuretable/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete item.');
      }

      setItems(items.filter((item) => item._id !== id));
      alert('Item excluído com sucesso!');
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('Erro ao excluir o item.');
    }
  };

  // Populate the form fields for editing an item
  const handleEditItem = (item) => {
    setEditItemId(item._id);  // Set the item ID to be edited
    setSelectedCategory({ value: item.categorieId, label: item.categorieName });  // Set selected category
    setImagePreviewUrl(item.image);  // Show the current image in preview
    fileInputRef.current.value = null;  // Reset the file input field so the user can choose a new image
    setImageFile(null);  // Clear the current image file
  };

  const resetForm = () => {
    setSelectedCategory(null);
    setImageFile(null);
    setImagePreviewUrl(null);  // Clear image preview when resetting the form
    setEditItemId(null);
    fileInputRef.current.value = null;  // Reset file input field
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setImagePreviewUrl(URL.createObjectURL(file));  // Generate a preview URL for the selected file
  };

  const categoryOptions = categories.length > 0
  ? categories
      // Filter categories based on selected gender and unused categories
      .filter(category => 
        // Ensure the category's gender matches selectedGender, if selectedGender is defined
        (selectedGender ? category.itemType?.gender === selectedGender.value : true) &&
        // Exclude categories that have already been used in items
        !items.some(item => item.categorieId === category._id)
      )
      // Map filtered categories to options format for the select component
      .map(category => ({
        value: category._id,
        label: category.name,
      }))
  : [];


  // Filter out categories that are already used in items
  /*
  const categoryOptions = categories.length > 0 ? categories
    .filter(category => !items.some(item => item.categorieId === category._id)) 
    .map(category => ({
      value: category._id,
      label: category.name,
    })) : [];
     */

  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Gerenciar Categorias e Imagens</Heading>

      {isDataLoading ? (
        <Box textAlign="center" mb={4}>
          <Spinner size="xl" mb={4} />
          <Text>Carregando dados...</Text>
        </Box>
      ) : (
        <>
          <Flex mb={4} direction="column" gap={4}>
            {/* Dropdown to select gender */}
            <Select
              placeholder="Selecionar Gênero"
              value={selectedGender}
              onChange={(gender) => {
                setSelectedGender(gender);
                setSelectedCategory(null);  // Reset category when gender changes
              }}
              options={genders.map(gender => ({
                value: gender._id,
                label: gender.name,
              }))}
              isClearable
            />
            {/* Dropdown to select category using react-select */}
            <Select
              placeholder="Selecionar Categoria"
              value={selectedCategory}
              onChange={setSelectedCategory}
              options={categoryOptions}
              isClearable
            />

            {/* Image preview */}
            {imagePreviewUrl && (
              <Image src={imagePreviewUrl} alt="Preview" boxSize="100px" mb={4} />
            )}

            {/* File input for selecting an image */}
            <Input
              type="file"
              accept="image/*"
              ref={fileInputRef}  // Attach ref to file input
              onChange={handleFileChange}
            />

            {/* Create/Update button */}
            <Button onClick={handleCreateOrUpdateItem} colorScheme="blue">
              {editItemId ? 'Atualizar Item' : 'Criar Item'}
            </Button>
          </Flex>

          {/* List of created items */}
          {items.length > 0 ? (
            <Table variant="simple" mt={4}>
              <Thead>
                <Tr>
                  <Th>Categoria</Th>
                  <Th>Imagem</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item) => (
                  <Tr key={item._id}>
                    <Td>{item.categorieName || 'N/A'}</Td>
                    <Td>
                      <Image src={item.image} alt={item.categorieName} boxSize="50px" />
                    </Td>
                    <Td>
                      <Button onClick={() => handleEditItem(item)} colorScheme="yellow" mr={2}>
                        <FaEdit />
                      </Button>
                      <Button onClick={() => handleDeleteItem(item._id)} colorScheme="red">
                        <FaTrash />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text>Nenhum item encontrado.</Text>
          )}
        </>
      )}
    </Box>
  );
};

export default ManageMeasureTables;
