import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SearchBar from './components/Searchbar/SearchBar';
import ProductPage from './components/Product/ProductPage';
import ProductList from './components/Product/ProductList';
import Footer from './components/Footer/Contacts';
import ContactForm from './components/ContactForm/ContactForm';
import AdminDashboard from './components/Admin/AdminDashBoard';
import DynamicPage from './components/Admin/DynamicPage';
import Login from './components/User/Login';
import Register from './components/User/Register';
import AccountPage from './components/User/AccountPage';
import SearchPage from './components/Searchbar/SearchPage'; 
import { UserProvider } from './components/User/UserContext';
import RelatedProductsPage from './components/Product/RelatedProductCarousel';
import CheckoutPage from './components/User/CheckoutPage';
import CartPage from './components/User/CartPage';
import NavItemPage from './components/Navbar/NavItemPage';
import ItemTypePage from './components/Navbar/ItemTypePage';

// Import the fetchSiteSettings function
import { fetchSiteSettings } from './services/siteSettings.js';
import './App.css'; 
import { useEffect } from 'react';
import PrivacyTermsPage from './components/TermsConditions/PrivacyTerms.js';
import HelpPage from './components/TermsConditions/Help.js';
import AboutUsPage from './components/TermsConditions/AboutUs.js';

function App({ setCustomTheme, logoUrl }) {
  useEffect(() => {
    fetchSiteSettings();
  }, []);



  return (
    <UserProvider>
      <Routes>
        {/* Admin routes */}
        <Route path="/admin/*" element={<AdminDashboard setCustomTheme={setCustomTheme} logoUrl={logoUrl} />} />

        {/* Non-admin routes */}
        <Route
          path="/*"
          element={
            <>
              <SearchBar />
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <Routes>
                    <Route path="/" element={<ProductList />} />
                    <Route path="/products/:id" element={<ProductPage />} />
                    <Route path="/cart" element={<CartPage />} />
                    <Route path="/checkout" element={<CheckoutPage />} />
                    <Route path="/related-products/:carouselItemId" element={<RelatedProductsPage />} />
                    <Route path="/contatos" element={<ContactForm />} />
                    <Route path="/politica_privacidade" element={<PrivacyTermsPage />} />
                    <Route path="/ajuda" element={<HelpPage />} />
                    <Route path="/sobrenos" element={<AboutUsPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/:url" element={<DynamicPage />} />
                    <Route path="/navitem/:navItemId" element={<NavItemPage/>} />
                    <Route path="/itemtype/:itemTypeId" element={<ItemTypePage/>} />
                  </Routes>
                </div>
              </div>
              <Footer />
            </>
          }
        />
      </Routes>
    </UserProvider>
  );
}

export default App;
