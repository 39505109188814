import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Heading,
  Select as ChakraSelect,
  Spinner,
} from '@chakra-ui/react';
import Select from 'react-select';
import { FaEdit, FaTrash, FaSearch } from 'react-icons/fa';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_VERCEL_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const ManageStock = () => {
  const [products, setProducts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [genres, setGenres] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');
  const [selectedItemType, setSelectedItemType] = useState('');
  const [quantity, setQuantity] = useState('');
  const [stock, setStock] = useState([]);
  const [editStockId, setEditStockId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsDataLoading(true);
        const [productData, sizeData, colorData, categoryData, genreData, itemTypeData] = await Promise.all([
          fetch(`${API_BASE_URL}api/productsList/all`).then(res => res.json()),
          fetch(`${API_BASE_URL}api/sizes`).then(res => res.json()),
          fetch(`${API_BASE_URL}api/colors`).then(res => res.json()),
          fetch(`${API_BASE_URL}api/categories`).then(res => res.json()),
          fetch(`${API_BASE_URL}api/genders`).then(res => res.json()),
          fetch(`${API_BASE_URL}api/itemTypes`).then(res => res.json()),
        ]);
        setProducts(Array.isArray(productData.products) ? productData.products : []);
        setSizes(Array.isArray(sizeData) ? sizeData : []);
        setColors(Array.isArray(colorData) ? colorData : []);
        setCategories(Array.isArray(categoryData) ? categoryData : []);
        setGenres(Array.isArray(genreData) ? genreData : []);
        setItemTypes(Array.isArray(itemTypeData) ? itemTypeData : []);
        fetchStock(); // Fetch stock after initial data load
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setIsDataLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchStock();
  }, [page]);

  const fetchStock = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}api/stock?page=${page}&limit=5`);
      const data = await response.json();
      setStock(Array.isArray(data.stock) ? data.stock : []);
      setTotalPages(data.totalPages || 1);
      setIsDataLoading(false);
    } catch (error) {
      console.error('Failed to fetch stock:', error);
      setIsDataLoading(false);
    }
  };

  const handleAddOrUpdateStock = async () => {
    if (!selectedProduct || !selectedSize || !selectedColor || quantity === '') {
      alert('Por favor, selecione todas as opções e insira uma quantidade válida.');
      return;
    }

    const newStock = {
      productId: selectedProduct.value,
      sizeId: selectedSize,
      colorId: selectedColor.value,
      quantity: parseInt(quantity, 10),
    };

    try {
      const response = editStockId
        ? await fetch(`${API_BASE_URL}api/stock/${editStockId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newStock),
          })
        : await fetch(`${API_BASE_URL}api/stock`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newStock),
          });

      if (!response.ok) {
        throw new Error('Falha ao adicionar ou atualizar o estoque.');
      }

      const data = await response.json();
      if (editStockId) {
        setStock(stock.map((item) => (item._id === editStockId ? data : item)));
      } else {
        setStock([...stock, data]);
      }

      resetForm();
      alert('Estoque adicionado ou atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao adicionar ou atualizar o estoque:', error);
      alert('Erro ao adicionar ou atualizar o estoque.');
    }
  };

  const handleEditStock = (stockItem) => {
    setEditStockId(stockItem._id);
    setSelectedProduct({ value: stockItem.productId._id, label: stockItem.productId.name });
    setSelectedSize(stockItem.sizeId._id);
    setSelectedColor({ value: stockItem.colorId._id, label: stockItem.colorId.name, color: stockItem.colorId.hex });
    setQuantity(stockItem.quantity.toString());
  };

  const handleDeleteStock = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}api/stock/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Falha ao excluir o item do estoque.');
      }

      setStock(stock.filter((item) => item._id !== id));
      alert('Item do estoque excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir o item do estoque:', error);
      alert('Erro ao excluir o item do estoque.');
    }
  };

  const handleSearch = async () => {
    setPage(1); // Reset to first page on search
    fetchStock();
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const resetForm = () => {
    setEditStockId(null);
    setSelectedProduct(null);
    setSelectedSize('');
    setSelectedColor(null);
    setQuantity('');
  };

  const productOptions = products.map(product => ({
    value: product._id,
    label: product.name,
  }));
  

  const sizeOptions = sizes.map(size => ({
    value: size._id,
    label: size.name,
  }));

  const colorOptions = colors.map(color => ({
    value: color._id,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ backgroundColor: color.hex, width: '20px', height: '20px', borderRadius: '50%', marginRight: '10px' }}></span>
        {color.name}
      </div>
    ),
    color: color.hex,
  }));

  const categoryOptions = categories.map(category => ({
    value: category._id,
    label: category.name,
  }));

  const genreOptions = genres.map(genre => ({
    value: genre._id,
    label: genre.name,
  }));

  const itemTypeOptions = itemTypes.map(itemType => ({
    value: itemType._id,
    label: itemType.name,
  }));


  return (
    <Box p={4}>
      <Heading size="md" mb={4}>Gerenciar Estoque</Heading>
      {isDataLoading ? (
        <Box textAlign="center" mb={4}>
          <Spinner size="xl"  mb={4}/>
          <Text>Carregando dados...</Text>
        </Box>
      ) : (
        <>
          <Flex mb={4} direction="column" gap={4}>
            <Select
              placeholder="Selecionar Produto"
              value={selectedProduct}
              onChange={setSelectedProduct}
              options={productOptions}
            />
            <ChakraSelect
              placeholder="Selecionar Tamanho"
              value={selectedSize}
              onChange={(e) => setSelectedSize(e.target.value)}
            >
              {sizeOptions.map(size => (
                <option key={size.value} value={size.value}>{size.label}</option>
              ))}
            </ChakraSelect>
            <Select
              placeholder="Selecionar Cor"
              value={selectedColor}
              onChange={setSelectedColor}
              options={colorOptions}
            />
            <Input
              placeholder="Inserir Quantidade"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
            <Button onClick={handleAddOrUpdateStock} colorScheme="blue">
              {editStockId ? 'Atualizar Estoque' : 'Adicionar Estoque'}
            </Button>
          </Flex>
          <Flex mb={4} gap={4}>
            <Input
              placeholder="Pesquisar por nome do produto"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <ChakraSelect
              placeholder="Selecionar Categoria"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {categoryOptions.map(category => (
                <option key={category.value} value={category.value}>{category.label}</option>
              ))}
            </ChakraSelect>
            <ChakraSelect
              placeholder="Selecionar Gênero"
              value={selectedGenre}
              onChange={(e) => setSelectedGenre(e.target.value)}
            >
              {genreOptions.map(genre => (
                <option key={genre.value} value={genre.value}>{genre.label}</option>
              ))}
            </ChakraSelect>
            <ChakraSelect
              placeholder="Selecionar Tipo de Item"
              value={selectedItemType}
              onChange={(e) => setSelectedItemType(e.target.value)}
            >
              {itemTypeOptions.map(itemType => (
                <option key={itemType.value} value={itemType.value}>{itemType.label}</option>
              ))}
            </ChakraSelect>
            <Button onClick={handleSearch} colorScheme="teal"><FaSearch /></Button>
          </Flex>
          <Table variant="simple" mt={4}>
            <Thead>
              <Tr>
                <Th>Produto</Th>
                <Th>Tamanho</Th>
                <Th>Cor</Th>
                <Th>Categoria</Th>
                <Th>Gênero</Th>
                <Th>Tipo de Item</Th>
                <Th>Quantidade</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {stock.map((stockItem) => (
                <Tr key={stockItem._id}>
                  <Td>{stockItem.productId?.name || 'N/A'}</Td>
                  <Td>{stockItem.sizeId?.name || 'N/A'}</Td>
                  <Td>{stockItem.colorId?.name || 'N/A'}</Td>
                  <Td>{stockItem.productId?.category?.name || 'N/A'}</Td>
                  <Td>{stockItem.productId?.gender?.name || 'N/A'}</Td>
                  <Td>{stockItem.productId?.itemType?.name || 'N/A'}</Td>
                  <Td>{stockItem.quantity}</Td>
                  <Td>
                    <Button onClick={() => handleEditStock(stockItem)} colorScheme="yellow"><FaEdit /></Button>
                    <Button onClick={() => handleDeleteStock(stockItem._id)} colorScheme="red"><FaTrash /></Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex mt={4} justifyContent="center">
            <Button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
              Anteriores
            </Button>
            <Text mx={4}>
              Página {page} de {totalPages}
            </Text>
            <Button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
              Próxima
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default ManageStock;
