import React, { useState } from 'react';
import { ChakraProvider, Box, SimpleGrid, Text, Flex, Button, Switch } from '@chakra-ui/react';
import GeographyChartMock from './Components/MapMock'; // Mock Map Component
import GeographyChart from './Components/Map'; // Real Map Component
import TransactionsMock from './Components/SalesTable/TransactionMock'; // Mock Transactions Component
import Transactions from './Components/SalesTable/Transaction'; // Real Transactions Component
import LineChartMock from './Components/LineChart/LineChartMock'; // Mock LineChart Component
import LineChart from './Components/LineChart/LineChart'; // Real LineChart Component
import SellerTransactionsMock from './Components/SellersChart/SellerTableMock'; // Mock Seller Transactions Component
import SellerTransactions from './Components/SellersChart/SellerTable'; // Real Seller Transactions Component
import StackedBarChartMock from './Components/SellersChart/StackedBarChartMock'; // Mock StackedBarChart Component
import StackedBarChart from './Components/SellersChart/StackedBarChart'; // Real StackedBarChart Component

const Dashboard = () => {
  // State to track whether to use mock data or real data
  const [useMock, setUseMock] = useState(true); // Start with mock by default

  const barChartData = {
    labels: ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM'],
    datasets: [
      {
        label: 'Donut',
        data: [100, 150, 200, 250, 300, 350, 400],
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
      },
      {
        label: 'Fries',
        data: [200, 250, 300, 350, 400, 450, 500],
        backgroundColor: 'rgba(54,162,235,0.2)',
        borderColor: 'rgba(54,162,235,1)',
        borderWidth: 1,
      },
      {
        label: 'Kebab',
        data: [150, 200, 250, 300, 350, 400, 450],
        backgroundColor: 'rgba(255,206,86,0.2)',
        borderColor: 'rgba(255,206,86,1)',
        borderWidth: 1,
      },
    ],
  };

  const getRandomNumber = (min, max) => {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber.toLocaleString('pt-BR');  // Formats with periods as thousand separators in Brazilian format
  };

  const getRandomPercentage = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(2);
  };

  const randomPercentage = `+${getRandomPercentage(5, 75)}%`;  // Random percentage between +5% and 75%

  const getRandomCurrency = (min, max) => {
    const randomValue = getRandomNumber(min, max);
    return `R$ ${randomValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
  };

  const randomCurrency = getRandomCurrency(10000, 50000);  // Random currency between R$ 10.000,00 and R$ 50.000,00

  return (
    <ChakraProvider>
      <Box p={5}>
        <Flex justify="space-between" align="center" mb={5}>
          <Text fontSize="2xl" fontWeight="bold">DASHBOARD</Text>

          {/* Switch to toggle between mock and real components */}
          <Flex align="center">
            <Text mr={2}>Modo Exemplo dos Gráficos</Text>
            <Switch
              colorScheme="blue"
              isChecked={useMock}
              onChange={() => setUseMock((prev) => !prev)} // Toggle between true and false
            />
          </Flex>

          <Button colorScheme="blue">Download</Button>
        </Flex>

        <SimpleGrid columns={{ sm: 2, md: 4 }} spacing={5} mb={5}>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Quantidade de Clientes</Text>
            <Text color="white" fontSize="2xl">{getRandomNumber(10000, 30000)}</Text>
            <Text color="green.400">{randomPercentage}</Text>
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Quantidade de Vendas</Text>
            <Text color="white" fontSize="2xl">{getRandomNumber(100000, 500000)}</Text>
            <Text color="green.400">{randomPercentage}</Text>
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Volume de Vendas</Text>
            <Text color="white" fontSize="2xl">{randomCurrency}</Text>
            <Text color="green.400">{randomPercentage}</Text>
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow">Ticket Médio de vendas || Volume Vendas/Qtd. Vendas</Text>
            <Text color="white" fontSize="2xl">{getRandomNumber(500000, 2000000)}</Text>
            <Text color="green.400">{randomPercentage}</Text>
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
          <Box bg="gray.700" p={5} borderRadius="md">
            <Text color="yellow" mb={5}>Volume</Text>
            {/* Conditionally render LineChartMock or LineChart based on useMock state */}
            {useMock ? <LineChartMock /> : <LineChart />}
          </Box>
          <Box bg="gray.700" p={5} borderRadius="md">
            {/* Conditionally render TransactionsMock or Transactions based on useMock state */}
            {useMock ? <TransactionsMock /> : <Transactions />}
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
          <Box bg="gray.700" p={2} borderRadius="md">
            <Text color="yellow" px={4} pt={3}>Rankings Vendedores</Text>
            {/* Conditionally render SellerTransactionsMock or SellerTransactions based on useMock state */}
            {useMock ? <SellerTransactionsMock /> : <SellerTransactions />}
          </Box>
          <Box bg="gray.700" p={2} borderRadius="md">
            <Text color="yellow" px={4} pt={3}>Quantidade de Vendas</Text>
            {/* Conditionally render StackedBarChartMock or StackedBarChart based on useMock state */}
            {useMock ? <StackedBarChartMock /> : <StackedBarChart />}
          </Box>
        </SimpleGrid>

        <Box bg="gray.700" p={5} borderRadius="md">
          <Text color="yellow" mb={5}>Volume de Vendas em Nível Nacional - Mapa</Text>
          {/* Conditionally render GeographyChartMock or GeographyChart based on useMock state */}
          {useMock ? <GeographyChartMock /> : <GeographyChart />}
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Dashboard;
