import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, IconButton, Circle, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import ProductCardRelated from '../Product/ProductCardRelated';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_VERCEL_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

// Define size variables
const BUTTON_MOBILE_HEIGHT = 'calc(2 * 419px + 2 * 16px)'; // Adjust this based on the actual height and spacing
const BUTTON_MOBILE_WIDTH = '30px';
const BUTTON_DESKTOP_HEIGHT = '390px';
const BUTTON_DESKTOP_WIDTH = '50px';

// Define spacing variables
const CARD_SPACING_MOBILE = '8px'; // Adjust the spacing between product cards on mobile
const CARD_SPACING_DESKTOP = '16px'; // Adjust the spacing between product cards on desktop

// Define button margin
const BUTTON_MARGIN = '8px'; // Adjust the margin between the buttons and the product cards

const ProductCarousel = ({ productId }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const productsPerPage = useBreakpointValue({ base: 4, md: 5 });
  const autoChangeInterval = 7000; // Change page every 7 seconds

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/products/related/${productId}`);
        const data = await response.json();
        setRelatedProducts(data.reverse());
      } catch (error) {
        console.error('Failed to fetch related products:', error);
      }
    };

    fetchRelatedProducts();
  }, [productId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextPage();
    }, autoChangeInterval);

    return () => clearInterval(intervalId);
  }, [currentPage, relatedProducts]);

  const totalPages = Math.ceil(relatedProducts.length / productsPerPage);

  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 0 ? totalPages - 1 : prevPage - 1));
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => (prevPage === totalPages - 1 ? 0 : prevPage + 1));
  };

  const goToPage = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const currentProducts = relatedProducts.slice(
    currentPage * productsPerPage,
    currentPage * productsPerPage + productsPerPage
  );

  return (
    <Box py={12}>
      <Heading as="h2" mb={6} textAlign="center">
        Produtos Relacionados
      </Heading>
      <Flex alignItems="center">
        <IconButton
          icon={<IoIosArrowBack />}
          onClick={prevPage}
          height={{ base: BUTTON_MOBILE_HEIGHT, md: BUTTON_DESKTOP_HEIGHT }}
          width={{ base: BUTTON_MOBILE_WIDTH, md: BUTTON_DESKTOP_WIDTH }}
          isDisabled={totalPages <= 1}
          opacity={1}
          _hover={{ opacity: 1 }}
          mr={BUTTON_MARGIN}
        />
        <SimpleGrid
          columns={{ base: 2, md: 5 }}
          spacing={{ base: CARD_SPACING_MOBILE, md: CARD_SPACING_DESKTOP }}
          overflow="auto"
          width="100%"
        >
          {currentProducts.map((product) => (
            <Box key={product._id} flex="0 0 auto">
              <ProductCardRelated product={product} />
            </Box>
          ))}
        </SimpleGrid>
        <IconButton
          icon={<IoIosArrowForward />}
          onClick={nextPage}
          height={{ base: BUTTON_MOBILE_HEIGHT, md: BUTTON_DESKTOP_HEIGHT }}
          width={{ base: BUTTON_MOBILE_WIDTH, md: BUTTON_DESKTOP_WIDTH }}
          isDisabled={totalPages <= 1}
          opacity={1}
          _hover={{ opacity: 1 }}
          ml={BUTTON_MARGIN}
        />
      </Flex>
      <Flex justifyContent="center" mt={4}>
        {Array.from({ length: totalPages }).map((_, index) => (
          <Circle
            key={index}
            size="10px"
            bg={currentPage === index ? 'blue.500' : 'gray.300'}
            mx={1}
            cursor="pointer"
            onClick={() => goToPage(index)}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default ProductCarousel;
